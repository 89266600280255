.sidebar-container {
  display: flex;
  flex-direction: column;
  font-size: 30px;
  margin-top: 20px;
  padding-right: 20px;
}

.icon-button {
  border-radius: 50%;
  height: 40px;
  padding: 0;
  margin: 0;
  width: 40px;
}

.delete-button {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 500; 
}

.timer-button-container {
  position: relative;
}

.timer-button {
  width: 100%;
}

.timers-list {
  display: flex;
  flex-direction: column;
}

.add-timer-container {
  align-items: baseline;
  display: flex;
  width: 20px;
}

.add-timer-input {
  font-size: 16px;
  text-align: center;
  width: 70px;
}

button {
  background-color: rgb(92, 92, 92);
  border: none;
  border-radius: 1em;
  color: #FFFFFF;
  display: inline-block;
  font-size: 30px;
  padding: 0.3em 1.2em;
  margin: 0 0.3em 0.3em 0;
  text-align: center;
}
button:hover{
  background-color: #FFFFFF;
  color: #000000;
}

.margin-top {
  margin-top: 50px;
}