.timer-container {
  background-color: rgb(43, 43, 43);
  width: 350px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.timer-container > button {
  background-color: rgb(43, 43, 43);
}

.timer-name {
  position: absolute;
  font-size: 26px;
  color: white;
  top: 0;
  left: 0;
  margin: 10px;
}

.delete-button {
  position: absolute;
  right: 0;
  top: 0;
}

.time-remaining {
  font-size: 45px;
  color: white;
  z-index: 1;
}

.main-content {
  width: 300px;
  height: 300px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.circle {
  height: 90%;
  width: 90%;
  background-color: rgb(92, 92, 92);
  border-radius: 50%;
  position: absolute;
  display: flex;
}

.button-group {
  position: absolute;
  top: 330px;
}

.button-group > button {
  background-color: rgb(43, 43, 43);
}

.button-group > button {
  margin: 5px;
}