.container {
  background-color: rgb(31, 31, 31);
  color: white;
  display: grid;
  grid-template-columns: 5fr 1fr;
  height: 100vh;
  justify-items: center;
}

.active-timers {
  display: flex;
  flex-wrap: wrap;
}

.active-timer {
  margin-left: 20px;
  margin-top: 20px;
}

.centered {
  text-align: center;
}

.text-size-large {
  font-size: 36px;
}

.alarm {
  background-color: white;
  color: rgb(31, 31, 31);
}